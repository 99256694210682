import React from "react";
import { useState, memo, useCallback, useEffect, useRef } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import HomeIcon from "./images/HomeIcon";
import Enery from "./images/eg1";
import Slitter from "./images/slitter";
import Logo from "./images/Logo";
import SettingIcon from "./images/SettingIcon";
import DistributionNetworkIcon from "./images/DistributionNetworkIcon";
import EMSIcon from "./images/EMSIcon";
import SMLRIcon from "./images/SMLRIcon";
import PlantConnectivityIcon from "./images/PlantConnectivityIcon";
import Styles from "./style.module.css";
import { FaBusinessTime, FaGalacticRepublic, FaHive } from "react-icons/fa";
import { AiOutlineShareAlt } from "react-icons/ai";
import { GiPowerLightning } from "react-icons/gi";
import { ImPower } from "react-icons/im";

function Sidebar() {
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const location = history.location.pathname.split("/");
  const pagename = location[location.length - 1]
  const siderbar = useRef()
  const logo_route = (localStorage.getItem('SlitterOEE') == 'true') ? "/slitter" : "/EMSOverview";

  const toggleMenu = (e) => {
    setIsOpen(!isOpen)
  }

  const handelDocumentClick = (e) => {
    if (siderbar.current.contains(e.target) === false) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener("click", handelDocumentClick)
    return () => {
      document.removeEventListener("click", handelDocumentClick)
    }
  }, [])

  return (
    <div className={`${Styles.sidebar} ${isOpen ? `${Styles.openMenu}` : ""}`} ref={siderbar}>
      <ul className={`${Styles.sideNav}`}>
        <li className={`${Styles.navItem} ${Styles.menuIcon}`}>
          <div className={`${Styles.navAnchor}`}>
            <div className={`${Styles.icon}`} onClick={toggleMenu}>
              <div className={`${Styles.customeMenu}`}>
                <div className={`${Styles.menuBtn}`}>
                  <span className={`${Styles.topLine}`}></span>
                  <span className={`${Styles.middleLine}`}></span>
                  <span className={`${Styles.bottomLine}`}></span>
                </div>
              </div>
            </div>
            <div className={`${Styles.logo}`}>
              <Link to={logo_route}>
                <Logo />
              </Link>
            </div>
          </div>
        </li>

        {(localStorage.getItem('SlitterOEE') == 'true') ? <>
          <li className={`${Styles.navItem} ${pagename === "slitter" ? `${Styles.active} active` : ""} toggleIconOnHover`}>
            <Link className={`${Styles.navAnchor}`} to="/slitter">
              <div className={`${Styles.icon}`}>
                <Slitter />
              </div>
              <div className={`${Styles.text}`}>Slitter OEE</div>
            </Link>
          </li>
        </> : ''}

        <>
          <li className={`${Styles.navItem} ${Styles.oeeIcon} ${pagename === "OEE" ? `${Styles.active} active` : ""} toggleIconOnHover`}>
            <Link className={`${Styles.navAnchor}`} to="/OEE">
              <div className={`${Styles.icon}`}>
                {/* <Slitter /> */}
                <FaGalacticRepublic color="#5d97f6" size={12} />
              </div>
              <div className={`${Styles.text}`}>OEE Calender Coater CFB </div>
            </Link>
          </li>
        </>

        {(localStorage.getItem('EnergyManagement') == 'true') ? <>
          <li className={`${Styles.navItem} ${pagename === "EMSOverview" ? `${Styles.active} active` : ""} toggleIconOnHover`}>
            <Link className={`${Styles.navAnchor}`} to="/EMSOverview">
              <div className={`${Styles.icon}`}>
                {/* { (pagename === "EMSOverview")? <img src="/image/dark_theme/daywise.png" alt="image" /> : <img src="/image/dark_theme/daywise.png" alt="image" /> } */}
                <EMSIcon />
              </div>
              <div className={`${Styles.text}`}>EMS Overview</div>
            </Link>
          </li>
        </> : ''}

        {(localStorage.getItem('EnergyManagement') == 'true') ? <>
          <li className={`${Styles.navItem} ${pagename === "ProcessWiseAnalysis" ? `${Styles.active} active` : ""} toggleIconOnHover`}>
            <Link className={`${Styles.navAnchor}`} to="/ProcessWiseAnalysis">
              <div className={`${Styles.icon}`}>
                {/* { (pagename === "ProcessWiseAnalysis")? <img src="/image/dark_theme/process.png" alt="image" /> : <img src="/image/common/procee_common.png" alt="image" /> } */}
                {(pagename === "ProcessWiseAnalysis") ? <img src="/image/dark_theme/process.png" alt="image" /> : <img src="/image/dark_theme/process.png" alt="image" />}

              </div>
              <div className={`${Styles.text}`}>Process Wise Analysis</div>
            </Link>
          </li>
        </> : ''}


        {(localStorage.getItem('EnergyManagement') == 'true') ? <>
          <li className={`${Styles.navItem} ${pagename === "Smlr" ? `${Styles.active} active` : ""} toggleIconOnHover`}>
            <Link className={`${Styles.navAnchor}`} to="/Smlr">
              <div className={`${Styles.icon}`}>
                {/* { (pagename === "Smlr")? <img src="/image/dark_theme/daywise.png" alt="image" /> : <img src="/image/dark_theme/daywise.png" alt="image" /> } */}
                <SMLRIcon />
              </div>
              <div className={`${Styles.text}`}>Smart Meter Live Reading</div>
            </Link>
          </li>
        </> : ''}





        {/* {(localStorage.getItem('EnergyManagement') == 'true')? <>
        <li className={`${Styles.navItem} ${pagename === "en1m" ? `${Styles.active} active` : ""} toggleIconOnHover`}>
          <Link className={`${Styles.navAnchor}`} to="/en1m">
            <div className={`${Styles.icon}`}>
              <Enery />
            </div>
            <div className={`${Styles.text}`}>Energy Monitoring</div>
          </Link>
        </li>


        </>:'' }
 */}




        {(localStorage.getItem('EnergyManagement') == 'true') ? <>
          <li className={`${Styles.navItem} ${pagename === "DistributionWiseAnalysis" ? `${Styles.active} active` : ""} toggleIconOnHover`}>
            <Link className={`${Styles.navAnchor}`} to="/DistributionWiseAnalysis">
              <div className={`${Styles.icon}`}>
                {(pagename === "DistributionWiseAnalysis") ? <img src="/image/dark_theme/daywise.png" alt="image" /> : <img src="/image/dark_theme/daywise.png" alt="image" />}
              </div>
              <div className={`${Styles.text}`}>Distribution Wise Consumption</div>
            </Link>
          </li>


        </> : ''}




        {(localStorage.getItem('EnergyManagement') == 'true') ? <>
          <li className={`${Styles.navItem} ${pagename === "DistributionNetwork" ? `${Styles.active} active` : ""} toggleIconOnHover`}>
            <Link className={`${Styles.navAnchor}`} to="/DistributionNetwork">
              <div className={`${Styles.icon}`}>
                {/* { (pagename === "DistributionNetwork")? <img src="/image/dark_theme/daywise.png" alt="image" /> : <img src="/image/dark_theme/daywise.png" alt="image" /> } */}
                <DistributionNetworkIcon />
              </div>
              <div className={`${Styles.text}`}>Distribution Network</div>
            </Link>
          </li>


        </> : ''}

        {(localStorage.getItem('PlantConnectivity') == 'true') ? <>
          <li className={`${Styles.navItem} ${pagename === "PlantConnectivity" ? `${Styles.active} active` : ""} toggleIconOnHover`}>
            <Link className={`${Styles.navAnchor}`} to="/PlantConnectivity">
              <div className={`${Styles.icon} ${Styles.plntconn}`}>
                <PlantConnectivityIcon />
              </div>
              <div className={`${Styles.text}`}>Plant Connectivity</div>
            </Link>
          </li>
        </>
        : null }
        {(localStorage.getItem('EnergyManagement') == 'true') ?
          <>
            <li className={`${Styles.navItem} ${pagename === "PowerConsumption" ? `${Styles.active} active` : ""} toggleIconOnHover`}>
              <Link className={`${Styles.navAnchor}`} to="/PowerConsumption">
                <div className={`${Styles.icon} ${Styles.plntconn}`}>
                  <ImPower color="#5d97f6" />

                </div>
                <div className={`${Styles.text}`}>Power Consumption</div>
              </Link>
            </li>
          </>
          : null}

        <li className={`${Styles.navItem} ${pagename === "ecrmis" ? `${Styles.active} active` : ""} toggleIconOnHover`}>
          <Link className={`${Styles.navAnchor}`} to="/ecrmis">
            <div className={`${Styles.icon}`}>
              {/* { (pagename === "Smlr")? <img src="/image/dark_theme/daywise.png" alt="image" /> : <img src="/image/dark_theme/daywise.png" alt="image" /> } */}
              <GiPowerLightning color="#5d97f6"/>
            </div>
            <div className={`${Styles.text}`}>Smart Meter Live Reading</div>
          </Link>
        </li>

        {/* : ''} */}
        {/* {(localStorage.getItem('GrafanaReport') == 'true') ? <> */}
        {/* <li className={`${Styles.navItem} ${pagename === "Connectivity" ? `${Styles.active} active` : ""} toggleIconOnHover`}>
            <Link className={`${Styles.navAnchor}`} to="/Connectivity">
              <div className={`${Styles.icon} ${Styles.plntconn}`}>
                <FaBusinessTime color="#5d97f6"/>
              </div>
              <div className={`${Styles.text}`}>Connectivity Report</div>
            </Link>
          </li> */}
        {/* </> */}
        {/* : null} */}


        {/* {(localStorage.getItem('EnergyManagement') == 'true')? <>
        <li className={`${Styles.navItem} ${pagename === "SmartMeter" ? `${Styles.active} active` : ""} toggleIconOnHover`}>
          <Link className={`${Styles.navAnchor}`} to="/SmartMeter">
          <div className={`${Styles.icon}`}>
             { (pagename === "SmartMeter")? <img src="/image/dark_theme/smartMeter.png" alt="image" /> : <img src="/image/dark_theme/smartMeter.png" alt="image" /> }
            </div>
            <div className={`${Styles.text}`}>Smart meter</div>
          </Link>
        </li>


        </>:'' } */}

      </ul>
      <ul className={`${Styles.sideNav}`}>
        <li className={`${Styles.navItem} ${pagename === "settings" || pagename === "change-password" ? `${Styles.active} active` : ""} toggleIconOnHover`}>
          <Link className={`${Styles.navAnchor}`} to="#">
            <div className={`${Styles.icon}`}>
              <SettingIcon />
            </div>
            <div className={`${Styles.text}`}>Settings</div>
          </Link>
          <ul className={`${Styles.subMenu}`}>
            <li className={`${pagename === "change-password" ? `${Styles.active} active` : ""}`}>
              <Link to="/settings/change-password">Change Password</Link>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
}
export default Sidebar;
